
import BGWorkerHandlers from './bgworker-handlers.js';
import Categories from './process-categories.js';
import ScriptPathNames from './process-script-paths.js';
import ProcessSchemas from './process-schemas.js';
import ExclusionGroups from './process-exclusion-groups.js';

export default [
	BGWorkerHandlers,
	Categories,
	ScriptPathNames,
	ProcessSchemas,
	ExclusionGroups
];
