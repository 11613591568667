/**
 */

export default {
	name: 'BGWorker.ProcessExclusionGroups',
	options: {
		table: 'v_process_exclusion_groups',
		schema: 'bgworker'
	}
};

