/**
 * Processes page with sidebar with process categories
 */
import template from './processes.html';

class ProcessesViewModel
{
	constructor (page) 
	{
		this.page = page;
		this.tabs = ko.observableArray([]); 
		this.current_title = ko.observable('All');
		this.title_visibility = ko.observable(true);

		this._current_tab_instance = null;
	}
	
	async load_tab (route, title)
	{
		window.localStorage.setItem('last_process_tab_id', route);
		window.localStorage.setItem('last_process_tab_title', title);

		let l_element = this.page.element.querySelector('#page_content');

		this.current_title(title);

		let l_page = Grape.pages.find_page_by_id(route);
		if (!l_page)
			throw new Error(`Page with id '${route}' not found`);
		let instance = await Grape.pages.load_page(l_page, l_element, {route: route, title: title}, {}, () => {});
		this._current_tab_instance = instance;
	}

	back_to_top ()
	{
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
}

class ProcessesPage 
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new ProcessesViewModel(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Processes';

		if (!window.Grape.currentSession || !window.Grape.currentSession.session_uuid)
		{
			window.Grape.navigate(`/grape-ui/login?rr=${encodeURI('/ui/bgworker/processes')}`);
			return;
		}

		let records = await Grape.cache.fetch('ProcessCategories');

		let categories = ['All'];
		for (let r of records)
			categories.push(r.category);

		for (let cat of categories) {
			this.viewModel.tabs.push({
				route: `[/processes/]subpage`,
				title: `${cat}`,
				icon: 'fa fa-caret-left'
			});
		}

		if (window.localStorage.getItem('last_process_tab_id') && window.localStorage.getItem('last_process_tab_title'))
			this.viewModel.load_tab(window.localStorage.getItem('last_process_tab_id'), window.localStorage.getItem('last_process_tab_title'));
		else
			this.viewModel.load_tab('[/processes/]subpage', 'All');
	}

	format_title (title)
	{
		return title.toLowerCase().replace(/ /g, '_');
	}

	teardown ()
	{
		if (this.viewModel._current_tab_instance?.teardown instanceof Function)
			this.viewModel._current_tab_instance.teardown();
	}
}

export default {
	route: '[/]ui/bgworker/processes',
	page_class: ProcessesPage,
	template: template
}
