

class WebSocketModel
{
	constructor (url)
	{
		if (url.startsWith('/'))
			this.url = url;
		else
			this.url = `/${url}`;

		this.socket = null;
		this.on_message = () => {};
		this.status_observable = ko.observable();
		this.socket_messages = ko.observableArray([]);
		this.retry_connection_timer = null;
		this.events = {};
	}

	open (subprotocol='json')
	{
		const on_message = (event) => {
			let data = event.data;
			if (data instanceof Blob)
			{
				data.text().then((txt) => {
					try {
						if (subprotocol == 'json')
						{
								let msg = JSON.parse(txt);
								this.on_message(msg);
						}
						else
								this.on_message(txt);
					} catch (err) {
						console.error(err);
					}
				}).catch(function(err) {
					console.error(err);
				});
			}
			else
			{
				try {
					if (subprotocol == 'json')
					{
							let msg = JSON.parse(event.data);
							this.on_message(msg);
					}
					else
							this.on_message(event.data);
				} catch (err) {
					console.error(err);
				}
			}
		}

		return new Promise((resolve, reject) => {
			this.status_observable('Connecting');
			let protocol = 'ws://';
			if (window.location.protocol == 'https:')
				protocol = 'wss://';
			let url = `${protocol}${window.location.host}${this.url}`;

			this.socket = new WebSocket(url, subprotocol);

			this.socket.addEventListener('open', (event) => {
				this.status_observable('Open');
				resolve();
			});

			this.socket.addEventListener('message', (msg) => { on_message(msg) });
			this.socket.addEventListener('close', (event) => {
				this.status_observable('Close');

				this.retry_connection_timer = setTimeout(() => { this.open().then(resolve).catch(reject); }, 1000);
			});
			
			this.socket.addEventListener('error', (event) => {
				console.error('socket error', event);
				this.status_observable('Error');
			});
		});
	}

	send(data)
	{
		
		return this.socket.send(data);
	}

	close ()
	{
		if (this.socket)
			this.socket.close();
	}

	on(event, listener)
	{
		if (typeof this.events[event] !== 'object')
			this.events[event] = [];
		this.events[event].push(listener);
	}

	off(event, listener)
	{
		if (typeof this.events[event] === 'object')
		{
			let idx = this.events[event].indexOf(listener);
			if (idx > -1)
				this.events[event].splice(idx, 1);
		}
	}

	removeListener(event, listener)
	{
		return this.off(event, listener);
	}

	emit(event, listener)
	{
		const args = [].slice.call(arguments, 1);
		if (typeof this.events[event] === 'object')
		{
			let listeners = this.events[event].slice();
			let length = listeners.length;
			for (let i = 0; i < listeners.length; i++)
				listeners[i].apply(this, args);
		}
	}

	once(event, listener)
	{
		this.on(event, function f () {
			this.removeListener(event, f);
			listener.apply(this, arguments);
		});
	}

}

export default WebSocketModel;
